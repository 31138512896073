import React, { useEffect, useLayoutEffect, useRef, useState }  from 'react';
import './home-hero.less';

import { forceSameDomain, changeInlineCss, isMobile, annotatedLog, isImageSpotVisible, isImageGreedy, makeImageSpotVisible, textContainerReduction} from '../utilities';
// 
const HomeHeroView = (props) => {
  let ref = useRef();
  let imageRef = useRef();
  const visible = (props.data.hidden==true) ? false : true;
  const [imageInline, setImageInline] = useState({});
  const [containerClass, setContainerClass] = useState("home-hero");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0
  });

  var image = "";
  if (props.data.image != undefined) {
      try {
        image = forceSameDomain(props.data.image[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + "/@@images/image");
      } catch (e) {
        image = "";
      }
  }

  const fontColor = (props.data.dark_text) ? "blackText" : "whiteText"; 

  useEffect(() => {
    if (visible && typeof window !== 'undefined') {
      const handleWindowResize = () => {
        // annotatedLog(`handleWindowResize`, `window.innerWidth=${window.innerWidth}`);
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };

      window.addEventListener('resize', handleWindowResize);

      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }  
  }, []);
  
  const adjustImagePosition = () => {
    const xspot = (props.data.xspot?.trim().length>0) ? parseInt(props.data.xspot) : imageRef.current.naturalWidth/2;
    const yspot = (props.data.yspot?.trim().length>0) ? parseInt(props.data.yspot) : imageRef.current.naturalHeight/2;

    // set a container width based on window state
    const containerWidth = Math.min(2000 /* MAX PAGE WIDTH */, windowSize.width);
    // console.log(`>>>> HomeHeroView adjustImagePosition() xspot=${xspot} yspot=${yspot}`, 
    //                   imageRef.current.naturalWidth, 
    //                   imageRef.current.naturalHeight, 
    //                   props.data);

    try {
      const imageAdjust = makeImageSpotVisible( //ref.current.clientWidth, 
        containerWidth,
        ref.current.clientHeight, 
        imageRef.current.naturalWidth, 
        imageRef.current.naturalHeight, 
        1.0, 
        0, 
        0, 
        xspot, 
        yspot);

      // console.log(`HomeHeroView adjustImagePosition triggerImagePosCalculations success: ${JSON.stringify(imageAdjust)}`);
      // annotatedLog("triggerImagePosCalculations", `%%%%%%%%%%% RESULTS ${JSON.stringify(imageAdjust)}`);  
      changeInlineCss(imageInline, setImageInline, 
                {
                  marginLeft: `${Math.round(imageAdjust.left)}px`,
                  marginTop: `${Math.round(imageAdjust.top)}px`,
                  scale: `${imageAdjust.scale}`
                }, 
                []
      );

    } catch (e) {
      console.log(`HomeHeroView adjustImagePosition triggerImagePosCalculations exception`, e);
      // annotatedLog("triggerImagePosCalculations", "adjustImagePosition error", e.toString());
    }
    
  }

  // useEffect(() => {
  //   if (isMobile()) {
  //     // const textFormatting = textContainerReduction(ref.current, props.data.title, ref.current.clientWidth, 0.8*ref.current.clientHeight, "'Montserrat', sans-serif", 85, 95, 0);
// 
  //     adjustImagePosition();
  //     // changeInlineCss(imageInline, 
  //     //   setImageInline, 
  //     //   {
  //     //     marginLeft: `${props.data.left}px`,
  //     //     marginTop: `${props.data.top}px`
  //     //   }, 
  //     //   []
  //     //   );
  //   }
    
  
  // }, [])

  
useEffect(() => {
  const narrow = props.data.narrow || false;
  const greyscale = props.data.greyscale || false;
  const darken = props.data.darken || false;
  const flip = props.data.flip || false;
  const overlay = (props.data.overlay!=undefined) ? props.data.overlay : "none";
  const smalltext = props.data.smalltext || false;

  const narrowClass = narrow ? "narrow" :"";
  const flipClass = flip ? "flip" :"";
  const greyscaleClass = greyscale ? "greyscale" :"";
  const darkenClass = darken ? "darken" :"";
  const smalltextClass = smalltext ? "smalltext" :"";

  // console.log(">>>> HomeHeroView useEffect[]", props.data);
  // console.log(props.data.overlay);

  setContainerClass (`home-hero ${narrowClass} ${greyscaleClass} ${darkenClass} ${smalltextClass} ${flipClass}`.trim());
  if (visible && imageRef.current.naturalWidth > 0) {
    setImageLoaded(true);
  }
}, []);


useEffect(() => {
  // console.log(`>>>> HomeHeroView useEffect[imageLoaded, windowSize], visible=${visible}; imageLoaded=${imageLoaded}`)
  if (visible && imageLoaded==true) {
    adjustImagePosition();
  }
}, [imageLoaded, windowSize])

  if (visible) {
    return( 
      <div className={containerClass} ref={ref}>
          <div className="hero-svg-image-container">
            <img className='swappable-image' src={image} style={imageInline} ref={imageRef} onLoad={(event) => setImageLoaded(true)}/>
            <div className="darken-overlay"/>
          </div>  
          <div className="hero-title-container">
            <h1 className={fontColor}>{props.data.title}</h1>
          </div>
      </div>
    )
  } else {
    return <></>
  }
  
};

export default HomeHeroView;